.navigation-button {
  position: absolute;
  top: 50%;
  z-index: 99;
  &--next {
    right: 0;
    transform: translate(100%, -50%);
  }
  &--prev {
    left: 0;
    transform: translate(-100%, -50%);
  }
}
.main-slider {
  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }
  }

  .slide-wraper--img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &--video {
    background: none;
    position: relative;
    z-index: 2;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
  }
  .slide-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
.section {
  padding: 50px 150px;

  @media (max-width: 768px) {
    padding: 50px 100px;
  }
  @media (max-width: 576px) {
    padding: 50px 0;
  }
}
