@import "./variables.scss";
@media (max-width: 992px) {
  .navbar-login {
    padding: 15px 15px 0px;
  }
  .login {
    .login-wrapper {
      position: relative;
      min-height: 100vh;
      color: white;
      background: url(http://localhost:3000/static/media/login-bg.f40beda524808f40c088.svg) no-repeat;
      background-size: auto;
      color: white;
      background-size: cover;
      text-align: center;

      > div {
        min-height: initial;
      }

      > div.d-flex:nth-child(1) {
        color: white;
        padding-top: 92px;
        padding-left: 15px;
      }

      > div.d-flex:nth-child(2) {
        padding-bottom: 74px;
        padding-right: initial;
        padding-left: 0px;
      }
    }
  }
  @media (max-height: 840px) {
    .login {
      h1 {
        font-size: 21px;
      }

      h2 {
        font-size: 25px;
        margin-bottom: 0px;
      }

      h3 {
        font-size: 17px;
        letter-spacing: 0px;
      }

      form {
        div:first-of-type {
          margin-top: 17px !important;
        }

        div:last-of-type {
          margin-bottom: 22px !important;
        }

        button {
          margin-bottom: 4px !important;
        }
      }
    }
  }
  .filters-background {
    top: 70px;
    height: calc(100vh - (80px + 70px));
  }
  .main-content-wrapper {
    height: calc(100vh - (80px + 70px));
  }
  .filter-container {
    top: 70px;
    height: calc(100vh - (80px + 100px));
  }

  @mixin icon-action-margins {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }

  h1 {
    font-size: 24px;
  }
  .icon-action {
    padding: 20px;
  }
  .navbar-main {
    padding-right: 8px;

    .icon-action {
      span {
        top: -4px;
        right: -5px;
      }
    }

    a.icon-action {
      @include icon-action-margins;
    }
  }
  .header-bar {
    padding-left: 8px !important;
    padding-right: 8px;
    min-height: 70px;

    h1 {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.36px;
      margin-bottom: 0px;
    }

    .add-ean {
      padding-left: 20px;
      @include icon-action-margins;
      padding-right: 20px;
      font-size: 0;
      width: 40px;

      &::before {
        @include center-element;
      }
    }

    a.icon-action {
      @include icon-action-margins;
    }

    .sort-icon {
      background-color: transparent;
      border-radius: 50%;

      &::before {
        height: 40px;
        width: 40px;
        background-size: 23px 23px;
        padding: 10px;
        background-position: center;
        z-index: 0;
        background-color: #f5f5f5;
        border-radius: 50%;
        cursor: pointer;
        left: 0px;
      }

      select {
        z-index: 1;
        position: relative;
        font-size: 0px;
        background-color: transparent;
        border: 0px;
        padding: 0px;
        background-image: none;

        option {
          font-size: 12px;
        }
      }
    }

    .search-togglers {
      font-size: 0px;
      margin-right: 0px !important;

      .filter-container {
        font-size: 14px;
      }

      span.arrow-after {
        font-size: 0px;
      }

      span {
        padding-left: 0px;
      }

      button.icon-action:nth-child(2) {
        display: none;
      }

      .filter-icon::before {
        @include center-element;
      }

      .arrow-right::after {
        width: 0px;
      }

      .search-togglers > span,
      .search-togglers > button {
        padding-left: 0px;
      }
    }

    select.w-224,
    div.w-224 {
      border: 0px;
      width: 40px;
      border: 0px !important;
      padding: 0px;
      background-size: 0px;
      cursor: pointer;
      margin-left: 10px !important;
    }

    div.w-224 {
      background-color: #f5f5f5;
      transition: 0.3s;

      &.opened-parent-absolute,
      &:hover {
        background-color: black;
      }
    }
  }

  .search-input-mobile {
    justify-content: center;
    // width: 40px;
    margin-right: 0px !important;
    margin-left: 10px !important;
    transition: 0.5s;
    background-color: #f5f5f5;
    border-radius: 20px;

    &.search.w-224 {
      width: auto !important;

      &:hover {
        background-color: #f5f5f5;
      }

      input {
        width: 40px !important;
      }
    }

    input {
      width: 40px;
      color: transparent;
      z-index: 2;
      background-color: transparent !important;

      &:focus {
        width: 163px;
        transition: 0.3s;
        padding-left: 36px !important;
        padding-right: 15px !important;
        color: black;
        z-index: 0;
      }
    }

    &.search.w-224 {
      input {
        &:focus {
          width: 163px !important;
        }
      }
    }

    .btn-search,
    .input-group-text {
      z-index: 1;
      left: 8px;
      transform: translateY(50%);
      padding: 0px !important;
      top: initial;
    }

    .search {
      width: auto;
      padding: 0px !important;
      border: 0px;
      background-color: #f5f5f5;
      width: 40px;
      margin-right: 0px !important;
    }

    .form-control {
      &::placeholder {
        font-size: 0px !important;
        margin-left: 10px;
      }
    }
  }
  .header-bar {
    .search-input-mobile.search.w-224 {
      position: relative;
      z-index: 999;
      width: 40px !important;
      // .input-group-text{
      //   z-index: 50000;
      // }
      input:focus {
        position: absolute;
        left: -100%;
        z-index: 999;
        background-color: #f5f5f5 !important;
        width: 190px !important;
        padding-left: 15px !important;
      }
    }
  }
  // .product-list {
  .product-wrapper {
    // padding: 0px;
    .image-col {
      flex-basis: 14%;
    }

    .product-description {
      flex-basis: 18%;
      margin-bottom: 9px;
      @media (min-width: 769px) {
        h3 {
          font-size: 15px;
          line-height: 21px;
        }
        .badge {
          font-size: 10px;
        }
      }
    }

    .price-wrapper {
      flex-basis: 11%;
      align-items: center;
      margin-left: 0px !important;
      // @include margin-lower-part;
    }

    .counter-cart-wrapper {
      flex-basis: 11%;
      width: initial;
      width: initial;
      // @include margin-lower-part;
      padding-top: 0px;
      margin-left: auto;

      .add-product {
        width: auto;
        margin: auto;
        margin-top: 3px;
        // .counter-wrapper{
        //   justify-content: center;
        // }
      }
    }

    form {
      > div {
        width: 274px;
      }
    }
  }
  // }
  div.main-content:nth-child(1) {
    // display: flex;
    padding: 15px;
  }
  .content-container {
    h3.first-header {
      margin-top: 22px;
    }
  }
  .content-container.with-header-bar .scrollbar-container {
    padding: 0px;

    .main-page {
      flex-direction: column;
    }

    .main-content {
      background-color: transparent;
      box-shadow: none;
      margin-bottom: 25px;
      padding: 22px 20px 0px;

      &.with-header {
        h2 {
          font-size: 18px;
          letter-spacing: -0.36px;
          line-height: 24px;
          margin-bottom: 26px;
        }
      }
    }
  }
  .custom-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 30px;

    * {
      font-size: 12px;
    }



    .custom-table-row, .custom-table-row.offer {
      flex-wrap: wrap;
      width: 50%;
      padding: 22px 0px 45px;
      border: 0px;
      box-shadow: 0px 0px 6px #00000026;
      border-radius: 10px !important;
      background-color: #ffffff;

      > div {
        padding: 0px;
        border-right: 0px;
      }

      // &:nth-child(1) {
      //   flex-basis: 50%;
      // }

      .custom-table-description {
        order: 1;
        padding-top: 20px;
        flex-basis: 100%;
        min-width: initial;
        @include paddings-x-25;

        h3 {
          font-size: 14px;
          line-height: 24px;
        }

        .parameters {
          display: none;
        }

        .type {
          font-size: 10px;
          letter-spacing: 0.3px;
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        .badge {
          margin-left: 0px;
        }

        .image {
          display: none;
        }
      }

      .custom-table-counter {
        padding: 15px 0px;
        order: 4;
        flex-basis: 100%;
        flex-direction: row !important;
        @include paddings-x-25;

        &::after {
          display: none;
        }

        > div {
          flex-basis: 50%;
        }
      }

      .custom-table-sum {
        order: 5;
        width: 212px;
        max-width: initial;
        background-color: #edf0f4;
        height: 40px;
        border-radius: 20px;
        align-self: center;
        @include custom-table-after(-22px, RAZEM NETTO);

        &::after {
          top: initial !important;
        }
      }

      .custom-table-delete {
        order: 6;
        padding: 0px;
        margin-left: auto;
        margin-right: 25px;
      }

      .custom-table-price {
        order: 0;
        flex-basis: 100%;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 14px;
        @include custom-table-header(-8px);

        &::after {
          top: -8px !important;
        }

        .price {
          font-size: 18px;
          margin-bottom: 5px;
        }
      }

      .custom-table-availabilty {
        order: 2;
        padding-bottom: 15px;
        @include paddings-x-25;

        .available {
          padding-left: 20px;

          &::after {
            width: 15px;
            height: 15px;
            left: 0px;
          }
        }
      }
      .custom-cell{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 0;
      }
    }

    .custom-table-row.offer {
      background-color: unset;
      border: 1px solid $blue;
      padding: unset !important;

      .product {
        background-color: $white;
        padding: 30px 0;
        border-radius: 10px;

      }
      .custom-table-checkbox {
        background-color: $blue;
        border-top-left-radius: 8px;

        .form-check-input {
          background-color: $blue;

        }
      }

      .offer-name {
        transform: translateY(-100%) translateX(-50%);
        left: 50%;
        padding: 5px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;


      }
      .custom-table-checkbox{
        border-bottom-right-radius: unset;
      }
      .custom-table-delete {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 40px;
        left: 0;
        background-color: #fce5e0;
        border-bottom-right-radius: 8px;
      }

    }

    &.second-mobile-card {
      .custom-table-row {
        padding: 25px;

        * {
          margin-bottom: 0px !important;
          padding: 0px !important;
          // padding-top: 0px !important;
          text-align: start;
          align-items: start;
          line-height: 14px;
          font-size: 10px !important;
        }

        div {
          flex-basis: 100%;
        }

        .custom-table-description {
          order: 0;

          h3 {
          }

          .badge {
            display: none;
          }

          .parameters {
            display: block;
          }
        }

        .custom-table-price::after {
          display: none;
        }

        .custom-table-sum::after {
          display: none;
        }
      }
    }

    .summary{
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-top: 1px solid black;
      background-color: white;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1490196078);
    }
  }
  .subtable {
    width: 320px !important;
    background-color: transparent;
    margin: auto;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: none;

    .subtable-wrapper {
      border: 0px;
      background-color: white;

      &:nth-of-type(2) {
        .subtable-row:last-of-type {
          border-bottom: 0px;
        }
      }

      .subtable-row {
        font-weight: bold !important;
        font-size: 12px !important;
        border-bottom: 1px solid #d9d9d9;

        > div:first-of-type {
          min-width: initial;
          width: 200px !important;
          padding-right: 16px !important;
        }

        > div:nth-of-type(2) {
          min-width: initial;
          max-width: initial;
          flex: auto;
          border-right: 0px;
        }

        &:nth-of-type(2) > div {
          padding-bottom: 10px;
        }

        .text-pill {
          background-color: white;
          padding: 0px;
        }
      }

      &:nth-child(1) {
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.1490196078);
        border-radius: 10px 10px 0px 0px;
      }

      &:nth-child(2) {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1490196078);

      }
      &:nth-child(3) {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1490196078);
        border-radius: 0px 0px 10px 10px;
      }
    }

    .subtable-single-wrapper {
      margin-top: 20px;
      border-radius: 10px 10px 0px 0px;
      box-shadow: 0px -1px 5px #00000026;

      .subtable-row {
        height: 64px;

        & > div {
          font-size: 18px;
          border: 0px;
          line-height: 51px;
        }
      }
    }
  }

  .left-side-button {
    display: none;
  }
  .cart-order-buttons {
    margin: auto !important;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 3px 5px #00000026;

    .notify {
      justify-content: center;
      text-transform: uppercase;
      font-size: 10px;
      margin-bottom: 25px;

      div {
        flex-basis: 100%;
        padding-right: 0px;
      }
    }
  }
  .order-buttons.gap {
    width: 320px !important;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    button {
      width: 270px;
    }

    button:nth-child(1) {
      order: 1;
    }
  }
  .content-container {
    .order-buttons.gap {
      button:nth-child(1) {
        order: 0;
      }
    }
  }
  .custom-table .equal-cols,
  .custom-table.mobile-alternative-card {
    margin-top: 0px;

    * {
      font-size: 10px;
      line-height: 14px;
    }

    // div:first-of-type {
    //   div::after {
    //     display: none !important;
    //   }
    // }
    .custom-table-row {
      padding: 25px;
      padding-bottom: 20px;
      border-radius: 0px !important;
      border-bottom: 1px solid #d9d9d9 !important;
      width: 75%;
      margin: auto;

      &:first-of-type {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1490196078);
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        height: initial;

        > div {
          border: 0px;
          font-weight: inherit;
          justify-content: start;

        }
      }

      &:last-of-type {
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1490196078);
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        border-bottom: 0px;
      }

      .custom-table-col {
        width: 100% !important;
        justify-content: start;
        gap: 3px;

        span:first-of-type {
          margin-right: 2px;
        }

        &:nth-of-type(2) {
          span {
            font-weight: bold;
          }
        }
      }

      .buttons-col {
        margin-top: 18px;
      }
    }
  }
  .content-container .tab-content {
    padding: 0px;
  }
  .product-page {
    .header-wrapper {
      .badge {
        margin-top: 6px;
      }
    }

    .product-page-content {
      order: 1;
      position: static;
      max-width: auto;

      .product-add-sticky {
        margin-top: 50px;
        margin-bottom: 95px;
        position: static;
      }
    }
  }
  .order-status-wrapper {
    flex-direction: column;
    align-items: center;
    width: 289px;
    margin: auto;

    .status-item {
      padding-top: 25px;
      padding-bottom: 25px;
      max-width: 200px;
    }
  }
  .product-added {
    .modal-dialog {
      max-width: 750px;

      .modal-content {
        padding: 20px;

        .product-description {
          padding-right: 0px;
        }
      }
    }

    .modal-body > div:nth-child(1) > div:nth-child(2) {
      order: 0;
    }

    .modal-body > div:nth-child(1) > div:nth-child(1) {
      order: 1;
      margin-top: 16px;
    }
  }
}
