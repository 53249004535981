$link-color: inherit;
$link-hover-color: $primary;

$btn-border-radius: 20px !default;
$btn-font-size: 14px !default;
$btn-line-height: 20px !default;
$btn-padding-y: 9px !default;
$btn-font-weight: bold !default;
$btn-padding-x: 23px !default;

$btn-close-width: 40px !default;
$btn-close-bg: url("/../public/img/close3.svg");
$btn-close-focus-shadow: none !default;
// $btn-border-width: 0px !default;

$dropdown-dark-color: inherit;
$dropdown-dark-bg: inherit;
$dropdown-font-size: inherit;
$dropdown-dark-link-hover-color: $primary;
$dropdown-dark-link-hover-bg: inherit;

// $badge-color: black;
//$badge-border-radius: 50% !default;
$badge-font-size: 12px !default;
$badge-padding-x: .75em !default;

$input-padding-x: 35px;
$input-padding-y: 0.85rem !default;
$input-padding-x-sm: 0px;
$input-font-family: "ibm-plex-sans";

$form-check-input-checked-bg-color: transparent !default;
$form-check-input-checked-bg-image: url("/../public/img/radio-button.svg");
$form-check-input-border: 0px solid transparent !default;
$form-check-input-focus-box-shadow: none !default;
$form-check-input-active-filter: none !default;
$form-check-input-width: 1.5em !default;
$form-check-input-checked-border-color: transparent;

$input-focus-border-color:             black !default;
$input-focus-box-shadow:                none !default;


$pagination-padding-y:              6.7px;
$pagination-padding-x:              14.35px;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;

$pagination-border-width:           2px;
$pagination-border-radius:          50%;
$pagination-margin-start:           10px;
$pagination-border-color:           transparent;

$pagination-focus-color:            black;
$pagination-focus-bg:               white;
$pagination-focus-box-shadow:       gray;
$pagination-focus-outline:          gray;


$pagination-hover-color:            black;
$pagination-hover-bg:               white;
$pagination-hover-border-color:     black;

$pagination-active-color:           black;
$pagination-active-bg:              white;
$pagination-active-border-color:    black;

$table-cell-vertical-align:   center !default;
$table-bg:                    white !default;
$table-th-font-weight:        bold !default;
$table-border-color: #141414 !default;
$table-group-separator-color: red !default;
$table-caption-color:         green !default;

$modal-header-border-width:         0px !default;
$modal-inner-padding:               0px !default;
$modal-sm:                          350px !default;
$modal-md:                          503px !default;
$modal-lg:                          962px !default;

$accordion-padding-x:                     0px !default;
$accordion-border-color: black !default;
$accordion-border-radius:                 0px !default;

$accordion-button-active-bg:              white !default;
$accordion-button-active-color:           black !default;

$accordion-button-focus-border-color:     black !default;
$accordion-button-focus-box-shadow:       none !default;

$accordion-icon-width:                    1.5rem !default;
$accordion-icon-color:                    black !default;
$accordion-icon-transition: 0s !default;
$accordion-icon-transform:                none !default;

$accordion-button-icon:url("/../public/img/plus.svg")!default;
$accordion-button-active-icon: url("/../public/img/minus.svg")!default;


$form-select-bg: transparent !default;     
$form-select-bg-position:           right 10px center !default;
$form-select-border-color:        black !default;
$form-select-focus-border-color:  black !default;
$form-select-focus-box-shadow:    none; 
$form-select-font-family: "neue-haas-unica", sans-serif !default;
$form-select-font-weight: 700 !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px
) !default;


