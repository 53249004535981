
.product-page-content{
  .swiper {
    width: 100%;
    height: 100%;
    // thumbs
    &:nth-child(2) {
      left: 0px;
      background-color: transparent !important;
      > div:nth-child(1) {
        max-width: 1340px;
        margin: auto;
      }
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper {
    width: 100%;
    // height: 300px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible !important;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  .close-button {
    display: none;
    height: 45px;
    width: 45px;
    color: #000000;
    position: fixed;
    top: 15px;
    right: 15px;
    font-size: 32px;
    text-align: center;
    cursor: pointer;
    z-index: 9999;
    opacity: 0.75;
  }

  .close-button:before {
    content: "×";
  }

  .close-button:hover,
  .close-button:active {
    opacity: 1;
  }
  .swiper-slide img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .swiper-thumbs {
    .swiper-slide {
      width: auto !important;
      img {
        object-fit: cover;
      }
    }
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    pointer-events: initial !important;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .accordion {
    .swiper-wrapper {
      align-items: start;
    }
    .swiper-button-next,
    .swiper-button-prev {
      transform: translateY(-50%);
    }
  }
  .fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    background-color: #161418;
    z-index: 9998;
    width: 100vw !important;
    margin-left: 0px !important;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    .gallery-top {
      max-width: 1340px;
      max-height: calc(100vh - 330px);
      margin: auto;
      .swiper-slide {
        background-color: transparent;
      }
    }
    &.swiper-container{
      margin-top: 0px;
    }
    .swiper{
      overflow: hidden !important;
    }
    .swiper-thumbs {
      .swiper-wrapper {
        justify-content: center;
        align-items: center;
      }
    }
    .swiper-button-prev {
      left: 20px !important;
    }
    .swiper-button-next {
      right: 20px !important;
    }
    .close-button {
      color: white;
    }
  }

  .fullscreen .gallery-top .swiper-slide {
    cursor: default;
    background-size: contain;
  }

  .fullscreen .close-button {
    display: block;
  }

  .fullscreen .gallery-top {
    height: 100%;
  }

  .fullscreen .gallery-thumbs {
    background-color: #000;
    height: 20%;
    position: fixed !important;
    bottom: 0;
    width: 100% !important;
    max-width: 100% !important;
    opacity: 0;
    z-index: 10;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.5s;
  }
  .swiper-container {
    // width: fit-content;
    overflow: hidden;
    padding-left: 15px;
    margin-left: -15px;
    padding-right: 15px;
    position: relative;
  }
  div.mySwiper2:first-of-type {
    // max-height: 429px;
    max-width: 749px;
    margin-left: 0px;
    margin-top: 40px;
    overflow-y: clip;
    overflow-x: initial;
    &::after,
    &::before {
      position: absolute;
      content: "";
      top: 0px;
      height: 100%;
      width: 15px;
      background-color: white;
      left: -15px;
      z-index: 9;
    }
    &::after {
      left: initial;
      right: -15px;
    }
  }

  div.swiper:last-of-type {
    //max-width: 749px;
    //margin-left: 0px;
    //margin-bottom: 40px;
    //max-height: 99px;
    //margin-top: 15px;
  }
  .mySwiper .swiper-slide {
    opacity: 1;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 40px !important;
    height: 40px !important;
  }
  .swiper-button-next {
    right: -15px !important;
  }
  .swiper-button-prev {
    left: -15px !important;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "" !important;
    background-color: black;
    border-radius: 50%;
    top: 50%;
    height: 40px;
    width: 40px;
    margin-top: 0px;
    transform: translateY(-50%);
    background-image: url("/../public/img/slider-next.svg");
    background-size: contain;
    background-position: center;
    position: absolute;
  }
  .swiper-button-prev::after {
    background-image: url("/../public/img/slider-prev.svg");
  }
  .fullscreen .gallery-thumbs:hover {
    opacity: 1;
    transition-delay: 0s;
  }
  // @media screen and (max-width: 992px) {
  //   .fullscreen {
  //     .swiper-button-next,
  //     .swiper-button-prev {
  //       top: 47% !important;
  //     }
  //   }
  // }

  @media screen and (max-width: 767px) {
    .accordion .accordion-swiper-container {
      .swiper-slide {
        a {
          width: 100%;
        }
      }
      width: 100% !important;
      img {
        height: auto !important;
      }
      // width: 552px !important;
      .swiper .swiper-button-next,
      .swiper .swiper-button-prev {
        top: 155px !important;
      }
      .swiper-button-next {
        right: -14px !important;
      }
      .swiper-button-prev {
        left: -13px !important;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .accordion .accordion-swiper-container {
      .swiper .swiper-button-next,
      .swiper .swiper-button-prev {
        top: 25vw !important;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .accordion .accordion-swiper-container {
      width: 688px !important;
    }

    // .swiper-button-next,
    // .swiper-button-prev {
    //   top: 42% !important;
    // }
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .accordion .accordion-swiper-container {
      width: 688px !important;
    }
    // .swiper-button-next,
    // .swiper-button-prev {
    //   top: 44% !important;
    // }
  }

  @media screen and (min-width: 1200px) {
    .accordion .accordion-swiper-container {
      width: 811px;
    }
  }
  @media (min-width: 1366px) {
    .accordion .accordion-swiper-container {
      width: 795px;
    }
  }
  @media (min-width: 1561px) {
    .accordion .accordion-swiper-container {
      width: 910px;
    }
  }

}
