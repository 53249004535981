@import "./variables.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  background-color: transparent;

  &.navbar-main {
    justify-content: end;
  }
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #cdcdcd !important;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cdcdcd !important;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cdcdcd !important;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cdcdcd !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cdcdcd !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #cdcdcd !important;
}
.navbar-main {
  height: $navbar-height;
  padding-right: 32px;
  .search {
    width: 288px;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 14px;
  }
  .navbar-text {
    color: #141414;
    font-size: 14px;
  }
  .btn-search {
    bottom: 50%;
    transform: translateY(50%);
    padding: 0px;
  }
  .icon-action {
    position: relative;
    span {
      position: absolute;
      top: -15px;
      right: -15px;
    }
  }
}
.navbar-login {
  padding: 57px 50px 0px;
}

// .navbar {

//   text-transform: uppercase;
//   font-weight: 700;
//   font-size: 13px;
//   height: $navbar-height;

//   .navbar-brand {
//     margin-left: 46px;
//     padding-left: 0;
//     height: auto;
//   }

//   .navbar-nav {
//     display: flex;
//     align-items: center;
//     height: 100px;
//     justify-content: space-around;
//     padding-left: 48px;
//     padding-right: 48px;

//     .nav-link {
//       color: white;
//       margin-right: 23px;

//       &:first-of-type {
//         padding-left: 0px;
//       }

//       &::first-letter {
//         text-transform: lowercase;
//       }

//       &:focus,
//       &:active,
//       &:hover {
//         color: white;
//       }

//       &:hover {
//         text-decoration: underline;
//       }

//       img {
//         margin-right: 10px;
//         padding-bottom: 2px;
//       }
//     }
//   }

//   button {
//     border: 0px;
//     background-color: transparent;
//     padding-right: 0px;

//     &:focus-visible,
//     &:focus,
//     &:active,
//     &:hover {
//       border: 0px;
//       background-color: transparent;
//       outline: none;
//       box-shadow: none;

//       img {
//         filter: invert(0);
//       }
//     }
//   }

//   .account {
//     background-color: $primary-color;
//     position: absolute;
//     top: 0;
//     right: 0;
//   }

//   .nav-links {
//     button {
//       font-size: 13px;
//     }
//   }
// }
