
$primary: #FFAB40;
$gray: #686868;
// secondary-gray (pills text bg)
$secondary-gray: #F5F5F5;
// Background-bg (marketing list)
$tertiary-gray: #F6F8FA;

$padding-left: 20px;
$success: #459A33;
$danger: #B64D34;
$warinig: #E2CA64;
$blue: #3179CE;
$info: #77ABE8;

$second-black: #141414;

$navbar-height: 80px;
$header-bar-height: 80px;

$color-hover: white;

$sum-table-width: 180px;
$price-table-width: 130px;
// .quinary-bg {
//   background-color: $quinary-color;
// }

// .primary-color {
//   color: $primary-color !important;
// }

// .py-30 {
//   padding-top: 30px;
//   padding-bottom: 30px;
// }

// $navbar-height: 100px;

// $configurator-left-width: 30%;
// $configurator-left-max-width: 630px;
// $configurator-left-min-width: 470px;