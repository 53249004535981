/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * ibm-plex-sans:
 *   - http://typekit.com/eulas/000000000000000077359441
 *   - http://typekit.com/eulas/00000000000000007735946b
 * neue-haas-unica:
 *   - http://typekit.com/eulas/00000000000000007735bb07
 *   - http://typekit.com/eulas/00000000000000007735bb1e
 *   - http://typekit.com/eulas/00000000000000007735bb24
 *   - http://typekit.com/eulas/00000000000000007735bb2a
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-10-24 13:27:28 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=pft6ovp&ht=tk&f=39512.39519.39521.39523.39886.39893&a=94170410&app=typekit&e=css");

@font-face {
font-family:"neue-haas-unica";
src:url("https://use.typekit.net/af/8a8abc/00000000000000007735bb07/30/l?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/8a8abc/00000000000000007735bb07/30/d?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/8a8abc/00000000000000007735bb07/30/a?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"neue-haas-unica";
src:url("https://use.typekit.net/af/5da8b8/00000000000000007735bb1e/30/l?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/5da8b8/00000000000000007735bb1e/30/d?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/5da8b8/00000000000000007735bb1e/30/a?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"neue-haas-unica";
src:url("https://use.typekit.net/af/579a71/00000000000000007735bb24/30/l?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/579a71/00000000000000007735bb24/30/d?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/579a71/00000000000000007735bb24/30/a?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"neue-haas-unica";
src:url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/l?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/d?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/a?primer=aa3db49b7e12e011e61ecb00739b9029c687b00e552f4ed4379a28af59832c30&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"ibm-plex-sans";
src:url("https://use.typekit.net/af/0626f2/000000000000000077359441/30/l?primer=f844ac635f788e48baf8e9886cb34e3650b7a207a743689303bcf70284ac367f&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/0626f2/000000000000000077359441/30/d?primer=f844ac635f788e48baf8e9886cb34e3650b7a207a743689303bcf70284ac367f&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/0626f2/000000000000000077359441/30/a?primer=f844ac635f788e48baf8e9886cb34e3650b7a207a743689303bcf70284ac367f&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"ibm-plex-sans";
src:url("https://use.typekit.net/af/53fac9/00000000000000007735946b/30/l?primer=f844ac635f788e48baf8e9886cb34e3650b7a207a743689303bcf70284ac367f&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/53fac9/00000000000000007735946b/30/d?primer=f844ac635f788e48baf8e9886cb34e3650b7a207a743689303bcf70284ac367f&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/53fac9/00000000000000007735946b/30/a?primer=f844ac635f788e48baf8e9886cb34e3650b7a207a743689303bcf70284ac367f&fvd=n8&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

.tk-neue-haas-unica { font-family: "neue-haas-unica",sans-serif; }
.tk-ibm-plex-sans { font-family: "ibm-plex-sans",sans-serif; }

.ibm {
  font-family: "ibm-plex-sans", sans-serif;
}

.neue-haas-unica {
  font-family: "neue-haas-unica", sans-serif;
}
