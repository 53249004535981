@import "./variables.scss";
@media (max-width: 576px) {
  .custom-table {
    .equal-cols .custom-table-row, .custom-table-row, .custom-table-row.offer {
      width: 90%;
    }
  }
  .accordion .list-accesories {
    width: 100%;
  }
  .list-accesories {
    width: 100%;

    .product-wrapper {
      padding: 0px;

      .relative-padding {
        margin-bottom: 0px;
      }
    }

    .show-accesories {
      left: initial;
    }
  }
  .sidebar {
    width: 100vw;
    max-width: initial;
    .sidebar-toggler{
     top: 10px
    }
  }
  .main-content.with-header {
    > div {
      //display: flex;
      //justify-content: center;
      //flex-wrap: wrap;
      h3 {
        width: 320px;
      }
    }
  }
  .content-container .tab-content {
    padding-left: 12px;
    padding-right: 12px;
  }
  .navbar-main .navbar-text {
    display: none;
  }
  .navbar{
    height: 60px;
  }
  @mixin padding-left-right {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .product-list {
    &.main-content-wrapper > .scrollbar-container {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  .product-wrapper {
    padding: 0px;
    border-radius: 10px;

    &:nth-child(1) {
      border-radius: 10px;
    }

    // > div > div {
    //   margin-left: 0px;
    //   margin-right: 0px;
    // }
    .relative-padding {
      height: 100%;
    }

    .image-col {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 0px;
      order: 0;
      border-right: 1px solid #d9d9d9;

      a {
        border-radius: 10px 0 0 0;
      }
    }

    .availability {
      margin-bottom: 9px;
    }

    .product-description {
      @include padding-left-right;
      flex-basis: 100%;
      order: 3;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d9d9d9;
      border-top: 1px solid #d9d9d9;

      h3 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
      }

      h4 {
        font-size: 10px;
        text-transform: uppercase;
      }

      p {
        display: none;
      }

      .d-flex {
        display: block !important;

        span {
          margin-left: initial !important;
          bottom: 1px;
        }
      }
    }

    .price-wrapper {
      flex-basis: 50%;
      align-items: center;
      order: 1;
      margin-top: 0px;
      position: relative;
      justify-content: space-between;
      padding-top: 0px;

      div:nth-of-type(1) {
        order: 1;
      }

      div:nth-of-type(2) {
        order: 0;
        margin-top: 3px;
      }

      div:nth-of-type(3) {
        order: 2;
      }

      .text-pill {
        background-color: transparent;
        font-size: 24px;
        letter-spacing: -0.48px;
        line-height: 26px;
        margin-bottom: 14px;
        margin-top: 4px;
      }

      .meter-price {
        top: -4px;
        position: static;

        * {
          top: 26px;
          left: calc(50% - 3px);
          transform: translate(-100%, -100%);
        }

        &.h-40 {
          height: initial;
          min-height: 40px;
        }
      }

      .old-price {
        transform: translateX(50%);
        margin-left: 3px;

        span {
          margin-top: 0px;
        }
      }
    }

    .price-unit-wrapper {
      order: 4;
    }

    .counter-cart-wrapper {
      @include padding-left-right;
      flex-basis: 100%;
      display: flex;
      order: 4;
      margin-left: auto;
      margin-bottom: 15px;
      margin-top: 3px;

      form > div {
        width: fit-content;
        justify-content: center !important;
      }

      .add-product {
        margin-top: 10px;
        margin-right: auto;
        width: 270px;
        padding: 0px;

        button {
          margin-bottom: 11px;
          width: 100%;
        }
      }

      .counter-wrapper {
        width: 270px;
        padding: 0px;

        .counter {
          width: 100%;
          margin-left: 0px;
        }
      }

      .select-product-wrapper {
        width: 100%;
      }

      select {
        width: 100%;
      }
    }
  }
  .accesories-button-wrapper {
    &::before {
      display: none;
    }

    > div {
      position: relative;
      bottom: 0px;
      background-color: #f5f5f5;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #d9d9d9;
      border-radius: 0 0 10px 10px;

      .show-accesories:hover::after,
      .show-accesories.opened::after {
        border-radius: 20px;
      }
    }
  }
  .product-page .product-page-content {
    .swiper-container {
      margin-left: 0px;
    }

    padding-right: 12px !important;

    .product-add-sticky {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .accordion .accordion-spec {
    .accordion-spec-data {
      flex-basis: 50%;
    }

    .accordion-spec-type {
      min-width: initial;
      flex-basis: 50%;
      padding-right: 15px;
    }
  }
}
