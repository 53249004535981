@import "./variables.scss";
@media (max-width: 1200px) {
  // .header-bar {
  //   padding-left: 20px;
  //   padding-right: 22px;
  // }
  .max-1386 {
    padding-left: 18px;
    padding-right: 18px;
  }
  .header-bar h1,
  .header-bar .h1 {
    font-size: 24px;
  }
  .content-container.with-header-bar .scrollbar-container {
    padding: 33px 0px;
  }
  .custom-table {
    * {
      font-size: 10px;
    }

    .custom-table-row {
      position: relative;

      &:first-of-type {
        .custom-table-checkbox {
          border-top-left-radius: 10px;
        }
      }

      > div {
        padding: 10px;
      }

      .custom-table-checkbox {
        position: absolute;
        top: 0px;
        left: 0px;
        width: fit-content;
        background-color: #f5f5f5;
        border-bottom-right-radius: 10px;
        padding: 0px;
        z-index: 5;
        overflow: hidden;

        input {
          height: 40px;
          width: 40px;
          background-size: 25px;
        }

        .form-check-input {
          background-color: #f5f5f5;
        }
      }


      .custom-table-description {
        flex-basis: 44%;
        min-width: 387px;

        h3 {
          margin-left: 0px;
        }

        .image {
          align-self: self-end;
          margin-top: 41px;
        }
      }


      .custom-table-sum {

        flex-basis: initial;
        inline-size: 90px;
        text-align: center;
      }
    }
  }
  .product-page .product-page-content {
    max-width: 677px;
    padding-right: 0px;

    .swiper-container {
      width: 100%;
    }
  }
  .subtable-row > div:nth-of-type(2) {
    min-width: $sum-table-width;
    max-width: $sum-table-width;
  }
}
