.checkbox-select {
  position: relative;
  width: 250px;

  button {
    display: flex;

    align-items: center;
    justify-content: space-between;
    height: 100%;
    background: #fff;
    width: 250px;
    line-height: 1.5;
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 7px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 7px;

    position: relative;
    font-weight: 500;
    border: 1px solid black;

    text-align: center;
    min-height: 33px;
    z-index: 1;

    i {
      font-weight: bold;
    }
    &[aria-disabled="true"] {
      background: #e9ecef;
    }
    span {
      font-size: 10px;
    }
  }

  button:hover {
    cursor: pointer;
  }

  ul.options {
    width: 250px;
    background: $tertiary-gray;
    border-radius: 5px;
    position: absolute;
    border: 1px solid black;
    font-size: 10px;
    display: none;
    list-style: none;
    padding: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    transform: translateY(100%);
    z-index: 5;
  }

  ul.show {
    display: block;
  }

  ul.options li {
    padding: 5px;
  }

  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover,
  ul.options li[aria-selected="true"] {
    background: #e9ecef;

    cursor: pointer;
  }
}
